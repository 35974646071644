<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="https://comstream.eu/img/comstream-logo.png" alt="comstream logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto align-items-center">
          <li class="nav-item">
            <router-link @click="removeClass()" class="nav-link" to="/">Home</router-link>
          </li>
          <li @mouseover="hover = true" @mouseleave="hover = false" class="nav-item">
            <router-link @click="removeClass()" to="/services" class="nav-link"
              >Services <i class="fa-solid fa-angle-down"></i
            ></router-link>
            <div :class="hover ? 'show' : 'hide'" class="dropdown-hover">
              <ul class="list-unstyled">
                <li>
                  <router-link @click="removeClass()" to="/services/direct-hiring"
                    >Direct Hiring</router-link
                  >
                </li>
                <li>
                  <router-link @click="removeClass()" to="/services/dedicated-nearshore-team"
                    >Dedicated Nearshore Team</router-link
                  >
                </li>
                <li>
                  <router-link @click="removeClass()" to="/services/freelancing-network"
                    >Freelancing Network</router-link
                  >
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <router-link @click="removeClass()" to="/expertise" class="nav-link"
              >Expertise</router-link
            >
          </li>
          <li @mouseover="hover2 = true" @mouseleave="hover2 = false" class="nav-item">
            <router-link @click="removeClass()" to="/careers" class="nav-link"
              >Careers <i class="fa-solid fa-angle-down"></i
            ></router-link>
            <div :class="hover2 ? 'show' : 'hide'" class="dropdown-hover">
              <ul class="list-unstyled">
                <li>
                  <router-link @click="removeClass()" to="/careers/openpositions"
                    >Job Openings</router-link
                  >
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <router-link @click="removeClass()" to="/about-us" class="nav-link"
              >About Us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link @click="removeClass()" to="/contact-us" class="nav-link"
              >Contact Us</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      hover: false,
      hover2: false,
    };
  },
  methods: {
    removeClass() {
      let element = document.getElementById('navbarSupportedContent');
      element.classList.remove('show');
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  padding: 0 80px;
  .navbar-brand {
    img {
      width: 200px;
      height: auto;
    }
  }
  .navbar-nav {
    .nav-item {
      position: relative;
      a {
        padding: 18px 12px;
        color: #000;
        &.router-link-active,
        &:hover {
          color: #fff;
          background: #a93224;
        }
      }
      .dropdown-hover {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 260px;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 0 0 6px 6px;
        padding: 12px 0 4px 0;
        z-index: 100;
        .list-unstyled {
          margin: 0;
          li {
            margin-bottom: 5px;
            a {
              display: block;
              text-decoration: none;
              padding: 6px 12px;
            }
          }
        }
        &.hide {
          display: none;
        }
        &.show {
          display: block;
        }
      }
      &:last-of-type {
        margin-left: 12px;
        a {
          color: #fff;
          padding: 8px 12px;
          background: #a93224;
          border-radius: 6px;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .navbar {
    .container-fluid {
      padding: 0;
    }
    .navbar-toggler {
      margin-right: 12px;
    }
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 999;
    width: 100%;
    .navbar-nav {
      align-items: flex-start !important;
      .nav-item {
        display: block;
        width: 100%;
        &:last-of-type {
          margin-left: 0;
          a {
            background: none;
            color: #000;
          }
        }
        .dropdown-hover {
          position: relative;
          display: block !important;
          width: 100%;
          background: none;
        }
      }
    }
  }
}
</style>
