<template>
  <Navigation />
  <div id="layout-scroll-container"></div>
  <router-view v-slot="{ Component }" style="min-height: 100vh">
    <transition enter-active-class="animate__animated animate__fadeIn animate__fast" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
  <Cookie v-if="Privacy === false" v-on:accepted="this.Privacy = true" />
</template>
<script>
import Navigation from '@/components/Navigations/mainNav';
import Footer from '@/components/Navigations/Footer';
import Cookie from '@/components/utils/cookie';
export default {
  components: {
    Navigation,
    Footer,
    Cookie,
  },
  data() {
    return {
      Privacy: false,
    };
  },
  methods: {
    checkPrivacy() {
      if (localStorage.getItem('policy') === 'accepted') {
        this.Privacy = true;
      } else {
        this.Privacy = false;
      }
    },
  },
  mounted() {
    this.checkPrivacy();
  },
};
</script>

<style lang="scss">
@import 'assets/css/bootstrap.min.css';
@import 'assets/css/custom.css';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  color: #2c3e50;
}

#layout-scroll-container {
  display: block;
}

.cookie-comply {
  position: fixed !important;
  z-index: 10;
}

.cookie-comply__button-accept {
  background: #a93224 !important;
}

.viewport {
  width: 1200px;
  margin: 0 auto;
  z-index: 10;
}

.page-header {
  position: relative;
  min-height: 70vh;
  color: #fff;
  text-align: center;
  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 8px;
    background: rgba(0, 0, 0, 0.1);
    padding: 12px;
    width: 85%;
    margin: 0 auto;
  }
  p {
    font-size: 1.5rem;
    margin-top: 30px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    z-index: 0;
  }
}

.btn-primary {
  padding: 10px 30px;
  font-size: 16px;
  background: #a93224;
  border-color: #a93224;
  box-shadow: none !important;
  &:hover {
    background: #a93224;
    border-color: #a93224;
  }
  &:active,
  &:focus {
    background: #a93224;
    border-color: #a93224;
  }
}

.background-mid-section {
  position: relative;
  min-height: 440px;
  width: 100%;
  margin: 35px 0;
  text-align: center;
  .viewport {
    width: 900px;
    z-index: 1;
    h3 {
      color: #fff;
      font-weight: 700;
      font-size: 1.9375rem;
      margin-bottom: 25px;
    }
    p {
      font-size: 1.125rem;
      color: #fff;
      margin-bottom: 25px;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
}
.call-to-action-boxes {
  padding: 70px 0;
  .box {
    position: relative;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49%;
    height: 20px;
    min-height: 300px;
    overflow: hidden;
    .content {
      text-align: center;
      padding: 0 30px 0 30px;
      z-index: 1;
      h3 {
        font-size: 30px;
        font-weight: 600;
        color: #fff;
      }
      p {
        font-size: 18px;
        color: #fff;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      z-index: 0;
    }
  }
}
.carousel__prev,
.carousel__next {
  background: #a93224 !important;
}
@media only screen and (max-width: 1200px) {
  .viewport {
    width: 100%;
    padding: 0 12px;
  }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
  .page-header {
    min-height: auto;
    padding: 80px 0 20px 0;
    h1 {
      font-size: 1.8rem;
      letter-spacing: 0.5px;
      width: 100%;
    }
    p {
      font-size: 1rem;
    }
  }
  .call-to-action-boxes {
    display: block !important;
    padding: 20px 0;
    .box {
      width: 100% !important;
      margin-bottom: 40px;
    }
  }
}
</style>
