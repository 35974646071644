<template>
  <div class="comply">
    <div class="header">Welcome to Comstream</div>
    <div class="content">
      We use cookies on our website to give you the most relevant user experience.
    </div>
    <div class="footer d-flex justify-content-between align-items-center">
      <button @click="acceptPolicy()" class="btn btn-primary">Accept</button>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    acceptPolicy() {
      localStorage.setItem('policy', 'accepted');
      this.$emit('accepted');
    },
  },
};
</script>

<style lang="scss" scoped>
.comply {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 320px;
  height: 220px;
  border-radius: 12px;
  background: #fff;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .header {
    text-align: center;
    padding: 12px 0;
    font-weight: 600;
    font-size: 1.2rem;
  }
  .content {
    text-align: center;
    padding: 12px 20px;
  }
  .footer {
    padding: 12px 20px;
  }
}
</style>
