import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/services',
    component: () => import('../views/Services.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/expertise',
    component: () => import('../views/Expertise.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/services/direct-hiring',
    component: () => import('../views/DirectHiring.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/services/dedicated-nearshore-team',
    component: () => import('../views/NearShoreTeam.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/services/freelancing-network',
    component: () => import('../views/FreelancingNetwork.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/services/dedicated-nearshore-team/nearshore-development',
    component: () => import('../views/NearshoreDevelopment.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/services/outsourced-support/',
    component: () => import('../views/OutsourcedSupport.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/careers',
    component: () => import('../views/Careers.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/careers/openpositions',
    component: () => import('../views/OpenPositions.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/about-us',
    component: () => import('../views/AboutUs.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/corporate-social-responsibility',
    component: () => import('../views/Csr.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/relocate-to-sweden',
    component: () => import('../views/RelocateToSweden.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/freelancers',
    component: () => import('../views/Freelancers.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/contact-us',
    component: () => import('../views/ContactUs.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/submit-cv',
    component: () => import('../views/SubmitCv.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') !== null) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
