import { reactive, readonly } from 'vue';

const state = reactive({
  isLogged: false,
  token: null,
  userName: null,
  firstName: null,
  avatar: null,
});

const methods = {
  setState(token, userInfo) {
    const user = JSON.parse(userInfo);
    state.isLogged = true;
    state.token = token;
    state.userName = user.name;
    state.firstName = user.fullName;
    state.avatar = user.avatar;
  },

  updateAvatar(avatar) {
    state.avatar = avatar;
  },

  resetState() {
    state.isLogged = false;
    state.userName = null;
    state.firstName = null;
    state.token = null;
    state.avatar = null;
  },
};
/* const changeInfo = (newInfo) => {}; */

export default {
  state: readonly(state),
  methods,
};
